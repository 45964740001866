<template>
  <div class="order-info">
    <header-bar :title="$t('loan_record_detail_title')" :custom-back="goHome" color="#ffffff"/>
    <div class="balance-div">
<!--      <template v-if="orderInfo.status !== 3 || currentIndex == null || currentIndex==formItems.length">-->
        <div class="left-div">
          <div class="wallet">
<!--            <img :src="walletImg"/>-->
          <span class="title">{{ $t('account_balance') }}</span>
          </div>
          <span class="selected-amount"> ₱{{
              orderInfo.status === 3 ? simpleCurrencyFormatter(orderInfo.balance) : 0.00
            }}</span>
        </div>
<!--      </template>-->
<!--      <template v-else v-for="(item, index) of formItems">-->
<!--        <div class="label-div" :key="index" v-if="currentIndex == index">{{ item.placeholder }}</div>-->
<!--      </template>-->
    </div>
<!--    <div class="top2"></div>-->
<!--    <div class="top3"></div>-->
    <div class="withdraw-div" v-if="orderInfo.status === 3 && currentIndex != null">
      <div v-for="(item, index) of formItems" :key="index">
        <div v-if="currentIndex==index">
          <div class="form-item">
            <span class="input-desc"><span class="required">*</span>{{ item.label }}</span>
            <van-field v-model="store[item.value]" :type="item.type" label-width="110px" autocomplete="false" class="input"
                       :formatter="item.formatter"
                       required :placeholder="$t('common_input_placeholder')"/>
          </div>
          <van-button type="info" block @click="withdraw(index+1)" :loading="loading"
                      :disabled="!store[item.value]" class="form-btn">
            {{ index === formItems.length - 1 ? $t('common_withdraw') : $t('common_next') }}
          </van-button>
        </div>
      </div>
<!--      <img :src="withdrawImg2" v-if="currentIndex==formItems.length"/>-->
    </div>
<!--    <div v-else class="withdraw-div">-->
<!--      <img :src="withdrawImg1"/>-->
<!--    </div>-->
    <div class="btn-div">
      <van-button type="info" block @click="clickOrder" :loading="loading"
                  v-if="currentIndex==null || currentIndex==formItems.length"
                  :disabled="btnDisabled || disabled" class="withdraw-btn">
        {{ $t(buttonDesc) }}
      </van-button>
      <van-button type="info" block @click="clickContact">
        {{ $t('click_to_contact_service') }}
      </van-button>
    </div>
    <van-popup v-model="showRechargeAmt" :close-on-click-overlay=false class="recharge-div">
      <van-field v-model.number="rechargeParams.amt" clearable :placeholder="$t('input_recharge_amount')" type="number"
                 input-align="left" :required="true"/>
      <div class="confirm-div">
        <button class="cancel" @click="showRechargeAmt=false">{{ $t('common_cancel') }}</button>
        <button class="confirm" :class="{disabled:!rechargeParams.amt}"
                :disabled='!rechargeParams.amt' @click="confirmRecharge">{{ $t('common_confirm') }}
        </button>
      </div>
    </van-popup>
    <van-popup v-model="showContactDialog" :close-on-click-overlay=true class="contact-div">
      <div>
        <span class="error-msg">Please contact customer service.</span>
      </div>
    </van-popup>
    <van-overlay :show="showReject" class-name="reject-box">
      <div class="wrapper" @click.stop>
        <span class="error-msg">Since there is no contact information for you
The system has declined, please contact customer service to reactivate</span>
        <van-button type="info" block @click="store.contactUs" v-if="store.showContact">
          {{ $t('click_to_contact_service') }}
        </van-button>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import store from "@/store";
import {orderService} from "@/services";
import withdrawErrorImg from '@/assets/withdraw-error.png';
import errorImg from '@/assets/error.png';
import withdrawImg1 from '@/assets/withdraw_1.png';
import withdrawImg2 from '@/assets/withdraw_2.png';
import balanceWalletImg from '@/assets/balance-wallet.png';
import walletImg from '@/assets/wallet2.png';

import {mapOrderStatusDesc, simpleCurrencyFormatter} from "@/common/formatter"

export default {
  name: "OrderInfo",
  data() {
    return {
      withdrawImg1,
      withdrawImg2,
      walletImg,
      store,
      simpleCurrencyFormatter,
      mapOrderStatusDesc,
      errorImg,
      withdrawErrorImg,
      balanceWalletImg,
      orderInfo: {},
      user: {},
      disabled: false,
      loading: false,
      interest: 0,
      shouldRepay: 0,
      showPassword: false,
      errorMsg: '',
      showRechargeAmt: false,
      rechargeParams: {
        amt: null,
      },
      showReject: false,
      showContactDialog: false,
      formItems: []
    }
  },
  computed: {
    btnDisabled() {
      return this.orderInfo.status > 4;
    },
    buttonDesc() {
      if (this.orderInfo.status === 4) {
        return 'common_repay';
      } else if (this.orderInfo.status === 5 || this.orderInfo.status === 12) {
        return 'has_order_refuse';
      } else if (this.orderInfo.status === 3) {
        return 'common_withdraw';
      } else {
        return 'common_refresh';
      }
    },
    currentIndex() {
      return this.$route.query.index;
    }
  },
  watch: {
    'store.withdrawAmount': function (value) {
      this.interest = (this.orderInfo.rate * value / 100000 * this.orderInfo.periodDays).toFixed(1) * 100 / 100;
      this.shouldRepay = this.interest + this.orderInfo.principal;
    },
  },
  created() {
    this.formItems = [{
      label: this.$t('withdraw_amount'),
      value: 'withdrawAmount',
      formatter: this.amtFormatter,
      placeholder: this.$t('input_withdraw_amount'),
      type: 'digit'
    }, {
      label: this.$t('bank_account_no'),
      value: 'withdrawBankcard',
      placeholder: this.$t('input_withdraw_account'),
      type: 'text'
    }, {
      label: this.$t('withdraw_code'),
      value: "withdrawPassword",
      placeholder: this.$t('input_withdraw_password'),
      type: 'text'
    },]
  },
  mounted() {
    this.getOrderInfo();
    this.getUserInfo();
    this.getLastWithdrawError();
  },
  methods: {
    withdraw(index) {
      if (index === this.formItems.length) {
        for (let index2 in this.formItems) {
          let item = this.formItems[index2]
          if (!store[item.value]){
            this.goIndex(index2)
            return;
          }
        }
        this.confirmWithdraw()
        return;
      }
     this.goIndex(index);
    },
    goIndex(index) {
      this.$router.push({
        "name": "orderInfo",
        params: {
          orderId: this.$route.params.orderId
        },
        query: {
          index: index,
        }
      })
    },
    clickContact() {
      if (store.showContact) {
        store.contactUs();
      } else {
        this.showContactDialog = true;
      }
    },
    amtFormatter(value) {
      if (value > this.orderInfo.balance) {
        return this.orderInfo.balance;
      }
      return value;
    },
    async getUserInfo() {
      this.user = await store.getUser();
    },
    async getOrderInfo() {
      this.orderInfo = await orderService.getOrderInfo({loanOrderId: this.$route.params.orderId})
      this.store.withdrawAmount = null;
      this.store.withdrawBankcard = null;
      this.store.withdrawPassword = null;
      this.showReject = this.orderInfo.status === 5
    },
    async recharge() {
      if (!this.orderInfo || !this.orderInfo.status) {
        this.$toast(this.$t('loan_records_empty'))
        return;
      }
      this.showRechargeAmt = true;
    },
    async confirmRecharge() {
      let link = await orderService.recharge(this.rechargeParams);
      if (link) {
        this.showRechargeAmt = false;
        window.open(link, "_blank");
      }
    },
    clickOrder() {
      this.disabled = true;
      setTimeout(() => this.disabled = false, 1000)
      if (this.orderInfo.status === 3) {
        this.withdraw(0);
      } else if (this.orderInfo.status === 4) {
        this.repayOrder();
      } else {
        this.getOrderInfo();
      }
    },
    async repayOrder() {

    },
    async confirmWithdraw() {
      const {verified} = await orderService.confirmWithdraw({
        password: this.store.withdrawPassword,
        withdrawAmount: this.store.withdrawAmount,
        bankcard: this.store.withdrawBankcard
      });
      if (!verified) {
        this.$toast(this.$t('withdraw_password_error'));
      } else {
        this.errorMsg = null;
        this.$toast(this.$t('submit_successfully'));
        this.showPassword = false;
        await this.getOrderInfo();
        this.goIndex(this.formItems.length);
      }
      this.loading = false;
    },
    async getLastWithdrawError() {
      this.errorMsg = await orderService.getLastWithdrawError();
    },
    goHome() {
      this.$router.back();
    }
  }
}
</script>
<style lang="less" scoped>
.order-info {
  min-height: calc(100% - 44px);
  padding-top: 44px;
  position: relative;
  //background: url("../../assets/wallet-background2.png") no-repeat;
  //background-size: cover;
  background-color: #A5DC9E;

  .error-tips {
    background: #FFFFFF;
    color: #F26666;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 12px;
    border-radius: 6px;

    img {
      width: 25px;
    }

    .error-msg {
      margin-left: 10px;
    }
  }

  .required {
    color: red;
    font-size: 16px;
  }

  .balance-div {
    margin: 20px 20px;
    padding: 30px 50px;
    background: @base-color;

    background-size: cover;
    height: 120px;

    border-radius: 8px;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .left-div {
      text-align: center;
      .wallet {
        display: flex;
        img {
          width: 20px;
        }
        .title {
          padding-left: 10px;
          color: #ffffff;
        }
      }
    }

    .right-div {
      display: flex;

      img {
        width: 150px;
      }
    }

    .label-div {
      height: 105px;
      margin: auto;
      text-align: center;
      display: flex;
      align-items: center;
      font-size: 26px;
      padding: 10px 50px 0;
    }

    .title {
      font-size: 16px;
    }

    .selected-amount {
      display: block;
      padding-left: 20px;
      margin-top: 15px;
      font-size: 36px;
      font-weight: 700;
      border-radius: 8px;
      color: #ffffff;
    }

    /deep/ .van-sidebar {

    }

    .custom-button {
      height: 8px;
      width: 8px;
      border-radius: 10px;
      border: 4px solid #6F45E9;
      background: #FFFFFF;
    }

    .value-rang {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      font-size: 14px;
      color: #83848B;

      .min-value {
        text-align: left;
      }

      .max-amount {
        text-align: right;
      }
    }
  }

  .top2 {
    background-color: #FF4267;
    height: 10px;
    margin: 0 30px;
    border-radius: 0 0 30px 30px;
  }

  .top3 {
    background-color: #5655B9;
    height: 10px;
    margin: 0 45px;
    border-radius: 0 0 30px 30px;
  }

  .amounts, .order-detail {
    padding: 15px;
    margin: 12px;
    background-color: #FFFFFF;
    border-radius: 6px;
  }

  .order-detail {
    padding: 0 15px;

    .order-detail-item {
      display: flex;
      flex-direction: row;
      padding: 15px;
      border-bottom: 1px solid #EEEEEE;

      &:last-child {
        border: none;
      }

      span {
        flex: 1;
      }

      .value {
        color: #333333;
        font-size: 16px;
        text-align: right;
      }

      .desc {
        color: #999999;
        font-size: 14px;
      }
    }
  }

  .withdraw-div {
    margin: 20px 15px;
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.2);
    background: #F5F5F5;
    border-radius: 8px;

    /deep/ .form-btn.van-button {
      height: 35px;
      font-size: 16px;
      background: #e6a23c;
      border: none;
      color: #ffffff;
      font-weight: 700;
      margin-top: 30%;
      width: 60%;
      border-radius: unset;
      margin-left: auto;
    }

    .form-item {
      background: #F5F5F5;
      padding: 15px;
      text-align: left;
      align-items: center;
      border-radius: 8px;

      .input-desc {
        font-size: 14px;
        margin-bottom: 10px;
        display: flex;
      }

      &:last-child {
        padding-bottom: 20px;
      }

      .input {
        border-radius: 8px;
        padding: 0;

        /deep/ input {
          border-radius: 5px;
          background: #ffffff;
          display: block;
          width: calc(100% - 10px);
          outline: 0;
          height: 40px;
          font-size: 14px;
          padding-left: 10px;
        }
      }
    }

    img {
      width: 100%;
    }
  }

  .btn-div {
    width: calc(100% - 40px);
    margin: 20px;
    display: flex;
    flex-direction: column;

    .withdraw-btn {
      margin-top: 192px;
    }
    button {
      height: 45px;
      color: #ffffff;
      border-radius: 10px;
      background: @base-color;
      font-weight: 300;

      &:first-child {
        margin-bottom: 20px;
      }

      &:last-child {
      }
    }
  }

  /deep/ .van-button {
    height: 45px;
    font-size: 16px;
    background: @button-color;
    border-radius: 6px;
    border: none;
    color: #ffffff;
    font-weight: 700;
  }

  .split-line {
    border: 0.5px dashed #8a929e8a;
    display: block;
    margin: 0 20px;
  }

  .whatsapp {
    margin: 20px;

    a {
      float: right;
    }
  }

  .van-popup {
    padding: 20px;
    width: 220px;
  }

  .error-box {
    background: rgba(0, 0, 0, 0.35);
    display: flex;
    justify-content: center;
    align-items: center;

    .wrapper {
      background: #FFFFFF;
      margin: 50px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 25px 45px;
      border-radius: 6px;
      font-size: 12px;

      img {
        width: 125px;
      }

      .error-title {
        font-weight: 600;
        color: #191919;
        margin: 8px 0 3px;
      }

      .error-msg {
        color: #535D66;
      }

      /deep/ .van-button {
        height: 35px;
        font-size: 14px;
        background: #FF3D00;
        border-radius: 6px;
        margin-top: 15px;
      }
    }
  }

  .green {
    color: green !important;
  }

  .reject-box {
    background: rgba(0, 0, 0, 0.35);
    display: flex;
    justify-content: center;
    align-items: center;

    .wrapper {
      background: #FFFFFF;
      margin: 50px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 20px;
      border-radius: 6px;
      font-size: 14px;

      .error-msg {
        color: #535D66;
      }

      /deep/ .van-button {
        height: 35px;
        font-size: 14px;
        background: #FF3D00;
        border-radius: 6px;
        margin-top: 15px;
      }
    }
  }

  .recharge-div {
    .confirm-div {
      display: flex;
      justify-content: space-between;
      margin: 20px 20px 10px 20px;

      .cancel, .confirm {
        background-color: @button-color;
        color: #ffffff;
        border-radius: 5px;
        box-shadow: none;
        border: none;
        font-size: 13px;
        padding: 5px 10px;

        &.disabled {
          opacity: 0.6;
        }
      }

      .cancel {
        background-color: #ffffff;
        border: 1px solid #333333;
        color: #333333;
      }
    }
  }

  .contact-div {
    width: 260px;
    border-radius: 20px;
  }
}
</style>
